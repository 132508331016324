import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const handleNavClick = (type) => {
  switch (type) {
    case "Home":
      document
        .getElementById("home")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      break;
    case "Why Us":
      document
        .getElementById("whyus")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      break;
    case "About Us":
      document
        .getElementById("about")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      break;
    case "Gallery":
      document
        .getElementById("gallery")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      break;
    case "Contact Us":
      document
        .getElementById("contact")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      break;

    default:
      break;
  }
};

export const MenuItem = ({ i, isOpen }) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div
        className="nav_item"
        onClick={() => {
          if (isOpen) handleNavClick(i);
        }}
      >
        {i}
      </div>
    </motion.li>
  );
};
