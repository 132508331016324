import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./menu_item";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ isOpen }) => (
  <motion.ul variants={variants}>
    {itemIds.map((i) => (
      <MenuItem i={i} key={i} isOpen={isOpen} />
    ))}
  </motion.ul>
);

const itemIds = ["Home", "Why Us", "About Us", "Gallery", "Contact Us"];
