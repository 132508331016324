import React from "react";
import "./style.css";
import aims_bjective from "./aims.svg";
import Animation from "../animation";

const AimsObjective = () => {
  return (
    <Animation bounce rotate>
      <div className="aims">
        <div className="illustrator">
          <img src={aims_bjective} alt="aims and objective" />
        </div>
        <div className="content">
          <h1 className="heading_text">Aims and Objective</h1>
          <div className="texts">
            <ul>
              <li>
                Create a supportive learning environment for creativity and
                curiosity.
              </li>
              <li>
                Encourage active lifestyles through sports and extracurricular
                activities.
              </li>
              <li>Cultivate leadership qualities and social responsibility.</li>
              <li>Prepare students for success in a globalized world.</li>
              <li>
                Build positive relationships with parents and the community.
              </li>
              <li>Strive for academic excellence and lifelong learning.</li>
            </ul>
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default AimsObjective;
