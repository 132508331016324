import React from "react";
import "./style.css";
import Animation from "../animation";

export default function Welcome() {
  return (
    <Animation>
      <div id="welcome" className="welcome_container">
        <div className="welcome_heading">
          <h1 className="heading_text">RA ACADEMY</h1>
        </div>
        <div className="welcome_content">
          <p>
            Welcome to <strong>Rashdah Ahsan Academy</strong>, established in
            2009. A co-educational day school following the CBSE curriculum. We
            empower students through quality holistic education, shaping them
            into future leaders. Our modern infrastructure fosters a supportive
            learning community for students, staff, and parents alike. With a
            commitment to excellence, we strive to provide an enriching
            educational experience for all.
          </p>
        </div>
      </div>
    </Animation>
  );
}
