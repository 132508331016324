import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import banner1 from "./banner1.jpeg";
import banner2 from "./banner2.jpeg";
import "./style.css";

export const images = [banner1, banner2];

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const quotes = [
  "The future belongs to those who believe in the beauty of their dreams.",
  "You are braver than you believe, stronger than you seem, and smarter than you think.",
  "The only way to do great work is to love what you do.",
  "In the middle of difficulty lies opportunity.",
  "Your attitude, not your aptitude, will determine your altitude.",
  "The secret of getting ahead is getting started.",
  "Dream big and dare to fail.",
];

export default function Banner() {
  const [[page, direction], setPage] = useState([0, 0]);
  const [quotesIdx, setQuotesIdx] = useState(0);

  const imageIndex = page % images.length;

  const paginate = (newDirection) => {
    setPage((prev) => [prev[0] + newDirection, newDirection]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      paginate(1);
      setQuotesIdx((prev) => prev + 1);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="banner">
      <div class="overlay">
        <div>
          {quotes[quotesIdx % quotes.length].split(" ").map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={`quotes-${quotesIdx}-${i}`}
              className="quote_words"
            >
              {el}{" "}
            </motion.span>
          ))}
        </div>
      </div>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      {/* <div className="next" onClick={() => paginate(1)}>
        {"‣"}
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        {"‣"}
      </div> */}
    </div>
  );
}
