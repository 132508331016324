import React from "react";
import Animation from "../animation";
import dirIllustration from "./message.png";
import "./style.css";

export default function DirMessage() {
  return (
    <div className="dir_container">
      <div className="dir_content">
        <Animation bounce>
          <div className="director_heading">
            <h1 className="heading_text">Director Message</h1>
          </div>
          <p className="dir_texts">
            As the Director of Rashdah Ahsan Academy, I'm thrilled to welcome
            you to our institution. Since 2009, we've provided a nurturing
            environment for students, offering a comprehensive education aligned
            with CBSE standards. Our focus on excellence and innovative teaching
            methods prepares students for success in academics and beyond.
            <br />
            <br />
            At Rashdah Ahsan Academy, we empower tomorrow's leaders. With modern
            facilities and a supportive community, we instill values of
            integrity, compassion, and resilience. Thank you for considering us
            for your child's education. We look forward to embarking on this
            exciting journey together.
          </p>
        </Animation>
      </div>
      <div className="dir_illustration_container">
        <Animation bounce>
          <img
            className="dir_illustion_img"
            src={dirIllustration}
            alt="Director Illustration"
          />
        </Animation>
      </div>
    </div>
  );
}
