import React from "react";
import "./style.css";
import whyUsIllustrator from "./why.svg";
import Animation from "../animation";

export default function WhyUs() {
  return (
    <Animation>
      <div className="why_us">
        <div className="illustrator">
          <img src={whyUsIllustrator} alt="why us" />
        </div>
        <div className="content">
          <h1 className="heading_text">Why Choose RA Academy?</h1>
          <div className="texts">
            <ul>
              <li>
                Excellence in Education: We offer a holistic approach to
                education, focusing on academic, social, and emotional
                development.
              </li>
              <li>
                Qualified Educators: Our passionate teachers create a supportive
                learning environment for every student.
              </li>
              <li>
                CBSE Curriculum: Our curriculum prepares students for success
                with a wide range of subjects and activities.
              </li>
              <li>
                Modern Facilities: State-of-the-art campus facilities enhance
                the learning experience.
              </li>
              <li>
                Character Development: We instill values like integrity and
                resilience in our students.
              </li>
              <li>
                Community Connection: Join our vibrant learning community for a
                fulfilling educational journey.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Animation>
  );
}
