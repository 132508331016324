import React from "react";
import DirMessage from "../dirmessage";
import Welcome from "../welcome";
import Banner from "./banner";
import "./style.css";

export default function Home() {
  return (
    <div id="home">
      <div id="banner_container">
        <Banner />
      </div>
      <Welcome />
      <DirMessage />
    </div>
  );
}
