import React from "react";
import WhyUs from "../whyus";
import AboutSchool from "../about_school";
import AimsObjective from "../aims_objective";

export default function About() {
  return (
    <div>
      <div id="whyus">
        <WhyUs />
      </div>
      <div id="about">
        <AboutSchool />
      </div>
      <div id="aims">
        <AimsObjective />
      </div>
    </div>
  );
}
