import { useEffect, useRef, useState } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use_dimensions";
import { MenuToggle } from "./menu_toggle";
import { Navigation } from "./navigation";
import "./style.css";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export default function Header() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  // const [disabledClick, setDisabledClick] = useState(false);

  // useEffect(() => {
  //   setDisabledClick(true);
  //   const timeOut = setTimeout(() => setDisabledClick(false), 1500);

  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, [isOpen]);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className="header"
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation isOpen={isOpen} />
      <MenuToggle
        toggle={() => {
          toggleOpen();
        }}
      />
    </motion.nav>
  );
}
