import "./App.css";
import About from "./components/about/about";
import Footer from "./components/footer";
import Gallery from "./components/gallery/index.";
import Header from "./components/header";
import Home from "./components/home";

function App() {
  return (
    <>
      <Header />
      <div id="home">
        <Home />
      </div>
      <div>
        <About />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <div id="contact">
        <Footer />
      </div>
    </>
  );
}

export default App;
