import { motion } from "framer-motion";
import React, { useMemo } from "react";

function Animation({ children, bounce, leftSlide, rightSlide, rotate }) {
  const cardVariants = useMemo(() => {
    const variants = {
      offscreen: {
        opacity: 0,
        scale: 0,
      },
      onscreen: {
        opacity: 1,
        scale: 1,
      },
    };
    if (bounce) {
      variants.offscreen.y = 300;
      variants.offscreen.y = 0;
      variants.onscreen.transition = {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      };
    }
    if (leftSlide) {
      variants.offscreen.x = -300;
      variants.onscreen.x = 0;
    } else if (rightSlide) {
      variants.offscreen.x = 300;
      variants.onscreen.x = 0;
    }
    if (rotate) {
      variants.offscreen.rotate = -180;
      variants.onscreen.rotate = 0;
    }
    return variants;
  }, []);

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={cardVariants}
    >
      {children}
    </motion.div>
  );
}

export default Animation;
