import React from "react";
import "./style.css";

export default function Footer() {
  const handleScroll = (id) => {
    const section1 = document.getElementById(id);
    section1.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <section id="footer">
        <div className="container">
          <div className=" myFooterCustom row  text-sm-left text-md-left">
            <div className="col-xs-12 col-sm-6 col-md-2">
              <h4>Our Location</h4>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="https://g.co/kgs/y643YBF" target="_blank">
                    <i className="bi bi-geo-alt-fill"></i>Pathra-Khirma Nanaura
                    Road,Ismailganj Rd, Mogalpura, Laheriasarai, Darbhanga,
                    Bihar 846003
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <h4>About Us</h4>
              <ul className="list-unstyled quick-links">
                <li>
                  <a
                    className="customAnchor"
                    onClick={() => handleScroll("whyus")}
                  >
                    <i className="fa fa-angle-double-right"></i>Why us
                  </a>
                </li>
                <li>
                  <a
                    className="customAnchor"
                    onClick={() => handleScroll("about")}
                  >
                    <i className="fa fa-angle-double-right"></i>About the School
                  </a>
                </li>
                <li>
                  <a
                    className="customAnchor"
                    onClick={() => handleScroll("aims")}
                  >
                    <i className="fa fa-angle-double-right"></i>Aims and
                    Objective
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <h4>Gallery</h4>
              <ul className="list-unstyled quick-links">
                <li>
                  <a
                    className="customAnchor"
                    onClick={() => handleScroll("gallery")}
                  >
                    <i className="fa fa-angle-double-right"></i>Image Gallery
                  </a>
                </li>
                <li>
                  <a
                    className="customAnchor"
                    onClick={() => handleScroll("gallery")}
                  >
                    <i className="fa fa-angle-double-right"></i>Video Gallery
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <h4>Contact Us</h4>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="tel:8521025210" target="_blank">
                    <i className="bi bi-telephone"></i>8521025210
                  </a>
                </li>
                <li>
                  <a href="raacademy.info@gmail.com" target="_blank">
                    <i className="bi bi-envelope"></i>raacademy.info@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
              <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/people/RAAcademy/100057610352330/?mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/ra__academy/?igsh=MTMycTZ3YzUwMWppZA%3D%3D"
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="raacademy.info@gmail.com" target="_blank">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
              <p className="h6">
                © All right Reversed.
                <a className="text-green ml-2" href="#" target="_blank">
                  Rashdah Ahsan Academy
                </a>
              </p>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
}
