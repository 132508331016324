import { motion } from "framer-motion";
import React, { useState } from "react";
import class1 from "../../Images/class1.jpg";
import cuteChild from "../../Images/cuteChild.jpg";
import prizeDistribution from "../../Images/prizeDistribution.jpg";
import science0 from "../../Images/scExhbtion0.jpg";
import science1 from "../../Images/scExhbtion1.jpg";
import science2 from "../../Images/scExhbtion2.jpg";
import science3 from "../../Images/scExhbtion3.jpg";
import science4 from "../../Images/scExhbtion4.jpg";
import science5 from "../../Images/scExhbtion5.jpg";
import science6 from "../../Images/scExhbtion6.jpg";
import sideView1 from "../../Images/sideview1.jpg";
import "./style.css";

function Image({ item }) {
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <img src={item.src} alt="" style={{ width: 300, height: 200 }} />
    </motion.div>
  );
}

export default function Gallery() {
  const items = [
    { src: cuteChild, type: "image" },
    { src: class1, type: "image" },
    { src: sideView1, type: "image" },
    { src: science0, type: "image" },
    { src: science1, type: "image" },
    { src: science2, type: "image" },
    { src: science3, type: "image" },
    { src: science4, type: "image" },
    { src: science5, type: "image" },
    { src: science6, type: "image" },
    { src: prizeDistribution, type: "image" },
    // { src: classView1, type: "video" },
    // { src: classView2, type: "video" },
    // { src: classView3, type: "video" },
  ];

  return (
    <div className="gallery">
      <div className="heading_wrapper">
        <h1 className="heading_text">Gallery</h1>
      </div>
      <div className="content">
        {items.map((item, idx) => (
          <Image item={item} key={`image-item-${idx + 1}`} />
        ))}
      </div>
    </div>
  );
}
