import React from "react";
import "./style.css";
import AboutTheSchool from "./about.svg";
import Animation from "../animation";

export default function AboutSchool() {
  return (
    <Animation bounce>
      <div className="about">
        <div className="content">
          <h2 className="heading_text">About the School</h2>
          <div className="texts">
            <ul>
              <li>
                Established in 2009, providing over a decade of quality
                education.
              </li>
              <li>
                Curriculum based on CBSE standards, ensuring academic
                excellence.
              </li>
              <li>
                Modern infrastructure equipped with state-of-the-art facilities
                and resources.
              </li>
              <li>
                Experienced and dedicated faculty committed to student success.
              </li>
              <li>
                Focus on character development, instilling values such as
                integrity, empathy, and resilience.
              </li>
              <li>
                Wide range of extracurricular activities to enhance students'
                talents and skills.
              </li>
              <li>
                Strong parent-school partnership, fostering a supportive
                learning community.
              </li>
            </ul>
          </div>
        </div>
        <div className="illustrator">
          <img src={AboutTheSchool} alt="about the school" />
        </div>
      </div>
    </Animation>
  );
}
